import { createPortal } from 'react-dom'
import { useMedia } from 'react-use'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '~/components/Button'
import { Image } from '../Image'
import styles from './ProductImageModal.module.css'

interface ImageModalProps {
  isOpen: boolean
  onClose: () => void
  src: string
  alt: string
}

export const ProductImageModal = ({
  isOpen,
  onClose,
  src,
  alt,
}: ImageModalProps) => {
  const isTabletScreen = useMedia('(min-width: 768px)', false)

  if (!isOpen) {
    return null
  }

  return (
    <>
      {createPortal(
        <div className={classNames(styles.modalOverlay, 'fixed inset-0')}>
          <div className='relative'>
            <Button
              variant='ghost'
              className={classNames(
                styles.closeButton,
                'absolute top-2 right-2 md:top-4 md:right-4'
              )}
              onClick={onClose}>
              <IconX
                size={24}
                style={{ color: 'var(--color-text-primary-blue)' }}
              />
            </Button>

            <TransformWrapper initialScale={isTabletScreen ? 1 : 2}>
              <TransformComponent>
                <Image src={src} alt={alt} className='md:w-full md:h-screen' />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>,
        document.body
      )}
    </>
  )
}
