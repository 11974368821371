/* eslint-disable camelcase */
import { useMemo } from 'react'
import classNames from 'classnames'
import Status from '~/components/Status/Status'

import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

export type HelperTextProps = {
  brand?: string
  availableForDelivery?: string
  inStockText?: string
  inStore?: boolean
  inStock?: boolean
  isMarketplaceProduct?: boolean
}

export const HelperText = ({
  brand,
  availableForDelivery,
  inStockText,
  inStore,
  inStock,
  isMarketplaceProduct,
}: HelperTextProps) => {
  const deliveryStatus = useMemo(() => {
    if (
      !inStock ||
      availableForDelivery.toLowerCase() === 'not available for delivery'
    ) {
      return 'error'
    }

    if (
      availableForDelivery &&
      availableForDelivery.toLowerCase() === 'available for delivery'
    ) {
      return 'success'
    }

    return 'warning'
  }, [inStock, availableForDelivery])

  const canRenderDeliveryStatus = availableForDelivery && !isMarketplaceProduct
  const canRenderStoreStatus = inStockText && !isMarketplaceProduct

  return (
    <div className='productCard-helperText h-12'>
      {isMarketplaceProduct && (
        <Status
          status='secondary'
          label={
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallExtraLineHeight}
              className='py-0.5 text-primary !pb-0'>
              Direct From Supplier
            </Typography>
          }
        />
      )}
      {canRenderDeliveryStatus && (
        <Status
          status={deliveryStatus}
          label={
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallExtraLineHeight}
              className='py-0.5 text-primary !pb-0'>
              {availableForDelivery}
            </Typography>
          }
        />
      )}
      {canRenderStoreStatus && (
        <Status
          status={inStore ? 'success' : 'error'}
          label={
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallExtraLineHeight}
              className={classNames(
                brand === 'rtm' && 'whitespace-nowrap',
                'py-0.5 text-primary !pb-0'
              )}>
              {inStockText}
            </Typography>
          }
        />
      )}
    </div>
  )
}
