import React from 'react'
import classNames from 'classnames'
import { GTM, GTMEvent } from '~/lib'
import { Button } from '../Button'
import Input from '../Input/Input'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './NewsLetterSubscription.module.scss'
import { NewsLetterSubscriptionProps } from './NewsLetterSubscription.types'

const NewsLetterSubscription = ({
  title,
  description,
  emailInputPlaceholder,
  subscribeButtonTitle,
}: NewsLetterSubscriptionProps) => {
  return (
    <div className={classNames('rounded-lg bg-white', styles.container)}>
      <div className='flex gap-2 flex-col mb-4 lg:mb-0 lg:pt-2'>
        {title && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.Heading5}>
            <span className='text-xl leading-display-large-desktop'>
              {title}
            </span>
          </Typography>
        )}
        {description && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegular}>
            <span className='leading-6 text-primary-blue'>{description}</span>
          </Typography>
        )}
      </div>
      <div
        className={classNames(
          'flex gap-1 flex-col lg:flex-row w-full lg:pt-4',
          styles.inputButtonContainer
        )}>
        <div className={styles.input}>
          <Input
            type='email'
            name='email'
            autoComplete='on'
            placeholder={emailInputPlaceholder}
          />
        </div>
        <Button
          theme={{
            root: classNames(styles.subscribeButton),
          }}
          status='primary'
          onClick={() => {
            // eslint-disable-next-line no-alert
            alert('Not Implemented')
            GTM.dispatch(GTMEvent.SUBSCRIBE_TO_NEWSLETTER)
          }}>
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegularBold}>
            {subscribeButtonTitle}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default NewsLetterSubscription
