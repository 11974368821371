import { VisuallyHidden } from '@overdose/components'
import { IconPlaylistAdd } from '@tabler/icons-react'
import classNames from 'classnames'
import { HintTooltip } from '~/components/HintTooltip'
import { AddToListButtonProps } from '~/components/ProductCard/ProductCard.types'
import { SaveProductTooltip } from '~/components/SaveProductTooltip'

export const AddToListButton = ({
  product,
  iconSize,
  className,
  buttonProps,
  tooltipPosition = 'bottom',
  queryId,
}: AddToListButtonProps) => {
  return (
    <HintTooltip message='Add to list' position={tooltipPosition}>
      <SaveProductTooltip
        buttonProps={buttonProps}
        toggle={
          <div className={classNames(className)}>
            <IconPlaylistAdd size={iconSize} strokeWidth='2' />
            <VisuallyHidden>Add to list</VisuallyHidden>
          </div>
        }
        products={[product]}
        queryId={queryId}
      />
    </HintTooltip>
  )
}
