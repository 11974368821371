import { Session } from 'next-auth/core/types'
import {
  Coordinates,
  CustomLocation,
  Result,
  ShippingFromPostcodeResponse,
  StoreSummary,
} from 'shared-types'
import { ApiError } from '../helpers/apiError'
import { IStoreClient } from '../types/Client'
import { request } from './request'

export class StoreClient implements IStoreClient {
  getNearestStore = (
    coordinates: Coordinates,
    session: Session,
    limit: number,
    sku: string,
    canClickAndCollect = false
  ): Promise<Result<StoreSummary[], ApiError>> => {
    const skuQueryParam = sku ? `&sku=${sku}` : ''
    const canClickAndCollectParam = canClickAndCollect
      ? `&canClickAndCollect=true`
      : ''

    return request(
      {
        method: 'GET',
        url: `/bff/stores?sortBy=closest&limit=${limit}&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}${skuQueryParam}${canClickAndCollectParam}`,
      },
      session
    )
  }

  getDeliverToPostcodeAvailabilityStatus = (
    session: Session,
    postcode: string,
    sku?: string,
    productPrice?: number
  ): Promise<Result<ShippingFromPostcodeResponse, ApiError>> => {
    return request(
      {
        method: 'GET',
        url: `/bff/stores/shipping-methods/${postcode}`,
        query: { sku, productPrice },
      },
      session
    )
  }

  getStoreByStorekey = (
    session: Session,
    storeKey: string,
    sku?: string
  ): Promise<Result<StoreSummary[], ApiError>> => {
    let url = `/bff/stores?storeKey=${storeKey}`
    if (sku) {
      url = `/bff/stores?storeKey=${storeKey}&sku=${sku}`
    }

    return request(
      {
        method: 'GET',
        url,
      },
      session
    )
  }

  getPostCodeData = (
    session: Session,
    keyword: string,
    country: string
  ): Promise<Result<CustomLocation[], ApiError>> => {
    return request(
      {
        method: 'GET',
        url: `/bff/stores/postcode-search/${keyword}`,
        query: country ? { country } : {},
      },
      session
    )
  }
}
