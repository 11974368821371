import { Fragment, useRef, useState } from 'react'
import { IconPlayerPlay, IconPlayerPause } from '@tabler/icons-react'
import classNames from 'classnames'
import MakerHubProjectYouTubePlayer from '../MakerHubProjectYouTubePlayer'
import styles from './VideoPlayer.module.css'
import { VideoPlayerProps, VideoCompProps } from './VideoPlayer.types'

const VideoComp = ({ source, muted, autoplay }: VideoCompProps) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(autoplay)

  const handleControlVideo = () => {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }

    setIsPlaying((prev) => {
      return !prev
    })
  }

  return (
    <div className={styles.wrapper}>
      <video ref={videoRef} muted={!!muted} autoPlay={!!autoplay}>
        <source src={source.src} type={source.type} />
        <track kind='subtitles' srcLang='en' label='English' />
      </video>
      {videoRef && (
        <div
          className={classNames(
            isPlaying ? styles.playing_layer : '',
            'flex items-center justify-center absolute top-0 left-0 w-full h-full'
          )}>
          <button
            type='button'
            className={styles.controlBtn}
            onClick={handleControlVideo}
            aria-label='control-button'>
            {isPlaying ? (
              <IconPlayerPause size={54} color='white' />
            ) : (
              <IconPlayerPlay size={54} color='white' />
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export const VideoPlayer = ({
  source,
  muted = true,
  autoplay = false,
}: VideoPlayerProps) => {
  return (
    <>
      {source.map((video, index) => {
        if (
          /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm.test(
            video?.src
          )
        ) {
          return (
            <Fragment key={index}>
              <MakerHubProjectYouTubePlayer
                youtubeUrl={video.src}
                muted={video?.muted ?? muted}
                autoplay={video?.autoplay ?? autoplay}
              />
            </Fragment>
          )
        }
        return (
          <VideoComp
            key={index}
            source={video}
            muted={video?.muted ?? muted}
            autoplay={video?.autoplay ?? autoplay}
          />
        )
      })}
    </>
  )
}
