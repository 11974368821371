export enum IframeContainerSizeType {
  FULL_PAGE = 'full-page',
  MANUAL_DIMENSIONS = 'manual-dimensions',
  ASPECT_RATIO = 'aspect-ratio',
}

export enum IframeContainerAlignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}
