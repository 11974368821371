import React from 'react'
import classNames from 'classnames'
import { Tooltip } from '~/components/Tooltip'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { HintTooltipProps } from './HintTooltip.types'

export const HintTooltip = ({
  message,
  tooltipClassName,
  containerClassName,
  children,
  position = 'bottom',
}: HintTooltipProps) => {
  return (
    <Tooltip
      theme={{ root: 'py-1 px-2 z-10' }}
      content={
        <Typography
          className={classNames('text-xs', tooltipClassName)}
          tag={TypographyTag.div}
          variant={TypographyVariant.BodyRegular}>
          {message}
        </Typography>
      }
      position={position}
      offset={[0, 8]}>
      <div className={classNames('flex items-center', containerClassName)}>
        {children}
      </div>
    </Tooltip>
  )
}
