import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { Session } from 'next-auth'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { StoreSummary } from 'shared-types'
import {
  useCustomer,
  useSession,
  useUserDeliveryPostcode,
  useUserLocation,
} from '~/hooks'
import { useToast } from '~/hooks/useToast'
import { request } from '../../customClients/request'
import { UserStoreProviderContext } from './UserPreferredStoreProvider.types'

const PreferredStoreConfigureDrawer = dynamic(
  async () => {
    const mod = await import('~/components/PreferredStoreConfigureDrawer')
    return mod.PreferredStoreConfigureDrawer
  },
  { ssr: false }
)

export const UserPreferredStoreContext =
  createContext<UserStoreProviderContext>(null)

const fetchStoreByKey = async (
  storeKey: string,
  session: Session,
  sku?: string
): Promise<StoreSummary> => {
  let url = `/bff/stores?storeKey=${storeKey}`
  if (sku) {
    url = `/bff/stores?storeKey=${storeKey}&sku=${sku}`
  }

  const response = await request<StoreSummary[]>(
    {
      method: 'GET',
      url,
    },
    session
  )

  if (response.getErrorSafe()) {
    return Promise.reject()
  }

  const stores = response.getValueSafe()
  return Promise.resolve(stores?.[0])
}

const PREFERRED_STORE_CACHE_KEY = 'preferred-store'

export const UserPreferredStoreProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [isStoreUpdated, setIsStoreUpdated] = useState(false)
  const { sku, setSku, setPreferredStoreByLatLong, setStoreByStoreKey } =
    useUserDeliveryPostcode()
  const [canClickAndCollect, setCanClickAndCollect] = useState(false)

  const { session } = useSession()
  const { showNotification, closeNotification } = useToast()

  const { customer } = useCustomer()

  const storeKey = session?.token?.storeKey || customer?.preferredStoreKey

  const {
    data: store,
    refetch: refetchStore,
    isFetching,
    isInitialLoading,
    isRefetching,
  } = useQuery(
    [PREFERRED_STORE_CACHE_KEY, storeKey, sku],
    () => {
      return fetchStoreByKey(storeKey, session, sku)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!storeKey,
    }
  )

  const isFetchingStore = isFetching || isInitialLoading || isRefetching

  const handleDrawerClose = useCallback(() => {
    setDrawerIsOpen(false)
  }, [setDrawerIsOpen])

  const { geolocate } = useUserLocation()

  const setPreferredStoreByLocation = useCallback(async () => {
    try {
      const location = await geolocate(true, false)
      if (!location) {
        return false
      }
      await setPreferredStoreByLatLong(location)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }, [geolocate, setPreferredStoreByLatLong])

  const hasSearchedForDefaultStoreOnLoad = useRef(false)
  useEffect(() => {
    if (!session) {
      return
    }

    if (
      !hasSearchedForDefaultStoreOnLoad.current &&
      !storeKey &&
      'requestIdleCallback' in window
    ) {
      requestIdleCallback(async () => {
        // Set default store based on location on load
        await setPreferredStoreByLocation()
        hasSearchedForDefaultStoreOnLoad.current = true
      })
    }
  }, [
    hasSearchedForDefaultStoreOnLoad,
    session,
    setPreferredStoreByLocation,
    storeKey,
  ])

  useEffect(() => {
    const handleRouteChange = () => {
      handleDrawerClose()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, handleDrawerClose])

  const handleSetStoreAndCloseDrawer = useCallback(
    async (storeKey: string, _postcode: string, name: string) => {
      await setStoreByStoreKey(storeKey)
      if (router.asPath !== '/store-finder') {
        await router.replace(router.asPath, undefined, {
          scroll: false,
        })
      }
      handleDrawerClose()
      showNotification({
        children: (
          <span>
            You&apos;ve selected <strong>{name}</strong> as your store
          </span>
        ),
        autoClose: true,
        id: storeKey,
        type: 'default',
        onClose: closeNotification,
      })
      setIsStoreUpdated(true)
    },
    [
      handleDrawerClose,
      setStoreByStoreKey,
      router,
      showNotification,
      closeNotification,
      setIsStoreUpdated,
    ]
  )

  const handleDrawerOpen = useCallback(
    (sku) => {
      setSku(sku)
      setDrawerIsOpen(true)
    },
    [setSku]
  )

  const value = useMemo<UserStoreProviderContext>(() => {
    return {
      promptUserToSelectStore: handleDrawerOpen,
      store,
      isFetchingStore,
      setPreferredStoreByLatLong,
      sku,
      setProductSkus: setSku,
      isStoreUpdated,
      setIsStoreUpdated,
      refetchStore,
      preferredStoreIsOpen: drawerIsOpen,
      canClickAndCollect,
      setCanClickAndCollect,
    }
  }, [
    handleDrawerOpen,
    setPreferredStoreByLatLong,
    setSku,
    sku,
    store,
    isFetchingStore,
    isStoreUpdated,
    drawerIsOpen,
    setIsStoreUpdated,
    refetchStore,
    canClickAndCollect,
    setCanClickAndCollect,
  ])

  return (
    <UserPreferredStoreContext.Provider value={value}>
      {children}

      <PreferredStoreConfigureDrawer
        open={drawerIsOpen}
        onClose={handleDrawerClose}
        onSelectStore={handleSetStoreAndCloseDrawer}
        sku={sku}
      />
    </UserPreferredStoreContext.Provider>
  )
}
