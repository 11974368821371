import { useState } from 'react'
import classNames from 'classnames'
import fallbackImage from '~/public/images/fallback.png'
import { Image } from '../Image'
import { ImageProps } from '../Image/Image.types'
import { ProductImageModal } from '../ProductImageModal'
import styles from './ProductImage.module.css'

export const ProductImage = (props: ImageProps) => {
  const { src, alt, ...rest } = props
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Image
        src={src || fallbackImage?.src}
        alt={alt}
        {...rest}
        onClick={() => {
          return setModalOpen(true)
        }}
        className={classNames(props.className, styles.root, 'cursor-zoom-in')}
      />
      <ProductImageModal
        isOpen={isModalOpen}
        onClose={() => {
          return setModalOpen(false)
        }}
        src={String(src) || fallbackImage?.src}
        alt={alt}
      />
    </>
  )
}
